body, html{
  font-family: 'Hanken Grotesk', sans-serif;
}

.header {
  box-sizing: border-box;
}

.clear{
  clear: both;
}

header{
  width: 100%;
  background-color: transparent;
  height: 16vh;
  position: fixed;
  padding: 3vh 8vh;
  box-sizing: border-box;
  transition: color 0.5s ease-out;
  z-index: 999;
}

header .bars{
  float: right;
  font-size: 3em;
  cursor: pointer;
}

.header .white{
  color: white;
}

.header .white .logo{
  filter: invert(100%) sepia(0%) saturate(7474%) hue-rotate(188deg) brightness(103%) contrast(105%);
}

.header .orange{
  color: rgb(228, 74, 52);
}

.header .orange .logo{
  filter: brightness(0) saturate(100%) invert(46%) sepia(34%) saturate(4679%) hue-rotate(337deg) brightness(89%) contrast(101%);
}

.header .green{
  color: rgb(62, 66, 47);
}

.header .blue{
  color: rgb(165, 183, 239);;
}

.header .blue .logo{
  filter: invert(69%) sepia(56%) saturate(367%) hue-rotate(194deg) brightness(97%) contrast(93%);
}

.header .green .logo{
  filter: invert(23%) sepia(14%) saturate(801%) hue-rotate(32deg) brightness(94%) contrast(89%);
}

.fakeHeader{
  width: 100%;
  background-color: rgb(228, 74, 52);
  height: 16vh;
}

footer{
  text-align: center;
  background-color: rgb(228, 74, 52);
  color: white;
  font-weight: 400;
  font-size: 1.4em;
  padding: 2em 0;
  line-height: 0.8em;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

footer p{
  margin: 0.8vh;
}

footer .social{
  margin-left: 40vw;
  width: 20vw;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: white;
  font-size: 3rem;
  padding-bottom: 1em;
}

header .logo{
  height: 7vh;
  float: left;
}

header .hamburger{
  float: right;
}

#homeVid{
  width: 100%;
  height: 100%;
}

.notop{
  margin-top: 0;
  padding-top: 0;
}

.nobottom{
  margin-bottom: 0;
  padding-bottom: 0;
}

.slide{
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.slide1{
  text-align: center;
  font-size: 3.3em;
  font-weight: 700;
  color: rgb(228, 74, 52);
  padding: 0 2em 0 2em;
}

.slide2{
  text-align: center;
  background-color: black;
  box-sizing: border-box;
}

.slide.padded{
  padding: 16vh 20vh;
}

.slide2 .columnsContainer{
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.slide2 .columnsContainer .column{
  width: 30%;
  height: 100%;
  font-weight: 800;
  color: white;
}

.slide2 .columnsContainer .column img{
  width: 40%;
}

.slide2 .columnsContainer .column .subtitle{
  margin-top: -0.2em;
  font-weight: 800;
  font-size: 1.8rem;
  margin-bottom: 2.5rem;
}

.slide2 .columnsContainer .column .content{
  padding: 0 1rem;
  font-size: 1.4rem;
  font-weight: 500;
}

.slide2 .columnsContainer .column.c1 .subtitle{
  color: white;
}

.slide2 .columnsContainer .column.c2 .subtitle{
  color: white;
}

.slide2 .columnsContainer .column.c0 .subtitle{
  color: white;
}

.slide3{
  text-align: center;
  font-weight: 700;
  background-color: rgb(228, 74, 52);
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  position: initial;
}

.slide3 .column.c0{
  width: 40%;
  z-index: 2;
  position: relative;
}

.slide3 .column.c0 img{
  width: 100%;
  border-radius: 2%;
}

.slide3 .mv{
  position: absolute;
  color: white;
  text-align: left;
  font-size: 1.8rem;
  left: 105%;
  bottom: 20%;
  font-weight: 700;
  width: 18em;
}

.slide3 .column.c1{
  text-align: right;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: white;
  font-size: 12vw;
  font-weight: 800;
  line-height: 0.9em;
}

.slide3 .column.c1 .small{
  font-size: 0.2em;
  line-height: 1em;
}

.slide5{
  background-color: white;
  color: rgb(228, 74, 52);
  font-weight: 800;
  font-size: 14em;
  line-height: 0.9em;
  height: 100vh;
}

.navMenu{
  position: fixed;
  background-color: rgb(62, 66, 47);
  height: calc(100% - 16vh);
  margin-top: 16vh;
  width: 100%;
  z-index: 998;
  text-align: right;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 0 7.5vh;
  color: white;
  font-weight: 800;
  font-size: 3rem;
  line-height: 4.2rem;
}

.navMenu a:hover{
  font-size: 3.1rem;
}

.navMenu .paddedItem{
  padding-right: 0em;
}

video{
  z-index: 1;
}

.headerWithNavMenu{
  background-color: rgb(62, 66, 47);
  color: white;
}

.headerWithNavMenu .logo{
  filter: invert(100%) sepia(0%) saturate(7474%) hue-rotate(188deg) brightness(103%) contrast(105%);
}

a, a:visited, a:active{
  color: inherit;
  text-decoration: inherit;
  transition: all 0.3s ease-in-out;
}

.slide3 .mv.mobile{
  display: none;
}

.navMenu .activities-group{
  height: 0;
  overflow: hidden;
  transition: all 0.5s ease-in-out;
  font-size: 2.3rem;
  line-height: 4rem;
}

.navMenu .activities-group.visible{
  height: 12.2rem;
}

/* NATURAL VOICE */

#naturalvoice .slide1{
  padding-top: 16vh;
  background-color: rgb(228, 74, 52);
  color: white;
  font-size: 11.1vw;
  height: 80vh;
  box-sizing: border-box;
}

#naturalvoice .slide2{
  font-size: 5vw;
  font-weight: 700;
  background-color: white;
  color: rgb(228, 74, 52);
  height: 70vh;
}

#naturalvoice .slide3{
  font-weight: 700;
  color: white;
  text-align: center;
  flex-direction: column;
  justify-content: center;
}

#naturalvoice .slide3 .logos-column{
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-around;
  margin: 4rem 0;
}

#naturalvoice .slide3 .logos-column img{
  width: auto;
  height: 6rem;
}
  

#naturalvoice .slide3 .text-column{
  width: auto;
}

#naturalvoice .content{
  font-size: 3.3rem;
}

#naturalvoice .slide7{
  font-size: 4.8vw;
}

#naturalvoice .slide4{
  height: 85vh;
}

#naturalvoice .slide4 .logo{
  height: 500px;
  width: 800px;
  box-sizing: border-box;
  margin-left: calc((100% - 800px) / 2);
  position: relative;
  text-align: center;
  line-height: 900px;
}

#naturalvoice .slide4 .logo img{
  width: 800px;
  height: auto;
}

#naturalvoice .slide4 .label{
  position: absolute;
  /*color: rgb(165, 183, 239)*/
  position: absolute;
  z-index: 2;
  font-size: 2em;
  font-weight: 700;
  line-height: normal;
}

#naturalvoice .slide7{
  font-weight: 700;
  color: white;
  text-align: center;
  flex-direction: column;
  justify-content: center;
  font-size: 3.3rem;
}


#naturalvoice .slide8{
  font-weight: 700;
  color: rgb(228, 74, 52);
  text-align: center;
  flex-direction: column;
  justify-content: center;
  font-size: 5rem;
  min-height: 40vh;
}

/* VOCE CANTATA */

#vocecantata .slide1{
  padding-top: 16vh;
  
  
  background-color: white;
  color: rgb(228, 74, 52);
  
  font-size: 6.5vw;
  height: 65vh;
  box-sizing: border-box;
}

#vocecantata .slide2{
  font-size: 3vw;
  font-weight: 700;
  background-color: rgb(228, 74, 52);
  color: white;
  min-height: 40vh;
}

#vocecantata .slide3{
  height: auto;

  background-color: white;
  color: rgb(228, 74, 52);
  
  font-size: 1.2rem;
  
  flex-direction: row;
  justify-content: space-between;
  
  /*background-image: url('./natural_voice_nv_01_op.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;*/
}

#vocecantata .slide3 h1{
  padding: 0 0;
  margin-top: 0;
  font-size: 5rem;
  text-align: left;
  font-weight: 700;
  margin-block-start: 0;
}

#vocecantata .slide3 table{
  width: 55%;
  text-align: left;
  margin-left: calc((100% - 55%)/2);
}

#vocecantata .slide3 table td{
  vertical-align: top;
  text-align: right;
  padding-bottom: 7vh;
}

#vocecantata .slide3 .desc{
  color: rgb(62, 66, 47);
}

#vocecantata .slide3 table td.last{
  padding-bottom: 0;
}

#vocecantata .slide3 table td.type{
  text-align: left;
  font-size: 1.2em;
  padding-right: 5vw;
}

#vocecantata .slide3 table.inverted td{
  text-align: left;
}

#vocecantata .slide3 table.inverted td.type{
  text-align: left;
  padding-left: 5vw;
}

/* VOCE AZIENDE */

#voceaziende .slide1{
  padding-top: 16vh;
  background-color: rgb(62, 66, 47);
  color: white;
  font-size: 6.5vw;
  height: 80vh;
  box-sizing: border-box;
}

#voceaziende .slide2{
  font-size: 2.9vw;
  font-weight: 700;
  background-color: white;
  color: rgb(62, 66, 47);
  height: 100vh;
}

#voceaziende .slide3{
  height: auto;
  color: white;
  font-size: 1.4rem;
  flex-direction: row;
  height: auto;
  background-color: white;
  color: rgb(62, 66, 47);
}

#voceaziende .slide3 h1{
  padding: 0 0;
  margin-top: 0;
  font-size: 5rem;
  text-align: left;
  font-weight: 700;
  margin-block-start: 0;
}

#voceaziende .slide3 table{
  width: 55%;
  text-align: left;
  font-size: 1.1rem;
  margin-left: calc((100% - 55%)/2);
}

#voceaziende .slide3 table td{
  vertical-align: top;
  text-align: right;
  padding-bottom: 7vh;
}

#voceaziende .slide3 table td.last{
  padding-bottom: 0;
}

#voceaziende .slide3 table td.type{
  text-align: left;
  font-size: 1.2em;
  padding-right: 5vw;
}

#voceaziende .slide3 .opacity{
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.9);
}

/* CONTACTS */
#contacts .slide1{
  padding-top: 16vh;
  background-color: rgb(165 183 239);
  color:  white;
  font-size: 9vw;
  height: 105vh;
  box-sizing: border-box;
  text-align: left;
}

/* VOCE PALATA */
#voceparlata .slide1{
  padding-top: 16vh;
  background-color: white;
  color: rgb(62, 66, 47);
  font-size: 5.6vw;
  height: 65vh;
  box-sizing: border-box;
}

#voceparlata .slide2{
  font-size: 3vw;
  font-weight: 700;
  background-color: rgb(62, 66, 47);
  color: white;
  min-height: 40vh;
}

#voceparlata .slide3{
  height: auto;
  font-size: 1.2rem;
  background-color: white;
  color: rgb(62, 66, 47);
}

#voceparlata .slide3 h1{
  padding: 0 0;
  margin-top: 0;
  font-size: 5rem;
  text-align: left;
  font-weight: 700;
  margin-block-start: 0;
}

#voceparlata .slide3 table{
  width: 55%;
  text-align: left;
  margin-left: calc((100% - 55%)/2);
}

#voceparlata .slide3 table td{
  vertical-align: top;
  text-align: right;
  padding-bottom: 7vh;
}

#voceparlata .slide3 table td.last{
  padding-bottom: 0;
}

#voceparlata .slide3 table td.type{
  text-align: left;
  font-size: 1.2em;
  padding-right: 5vw;
}

#voceparlata .slide3 table.inverted td{
  text-align: left;
}

#voceparlata .slide3 table.inverted td.type{
  text-align: left;
  padding-left: 5vw;
}

/* ABOUT US */
#aboutus .slide1{
  padding-top: 16vh;
  color: white;
  font-size: 15vw;
  min-height: 100vh;
  font-weight: 800;
  box-sizing: border-box;
  background-color: rgb(228, 74, 52);
  justify-content: space-between;
  text-align: left;
}

#aboutus .slide1 .marco{
  display: flex;
  flex-direction: row;
  justify-content: left;
  font-size: 2rem;
  text-align: left;
  margin-top: 2rem;
}

#aboutus .slide1 .marco .marcomarco{
  font-size: 4rem;
}

#aboutus .slide1 .marco img{
  height: 50vh;
  margin-right: 2em;
  margin-bottom: -5rem;
}

#aboutus .slide2,
#aboutus .slide3{
  font-size: 4vw;
  font-weight: 800;
  background-color: white;
  color: rgb(228, 74, 52);
  height: 90vh;
  text-align: left;
  display: block;
  background-image: url("natural_voice_nv_02.png");
  background-size: cover;
  background-position: left calc(61vw) top 0px;
  background-repeat: no-repeat;
}

#aboutus .slide3{
  text-align: right;
  background-position: left calc(-60vw) top calc(-7vw);
}

#aboutus .slide2 .claim,
#aboutus .slide3 .claim{
  width: 60%;
}

#aboutus .slide3 .claim{
  float: right;
}

#aboutus .slide4{
  font-size: 3vw;
  font-weight: 700;
  background-color: rgb(228, 74, 52);
  color: white;
  height: 30vh;
  text-align: center;
}