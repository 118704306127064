@media screen and (max-width: 1090px) {
    header{
      width: 100vw;
      background-color: rgb(228, 74, 52);
      height: 10vh;
      position: auto;
      padding: 0 2vh;
      box-sizing: border-box;
      transition: color 0.5s ease-out;
      z-index: 999;
      display: flex;
      flex-direction: column;
      justify-content: center;
      -webkit-backface-visibility: hidden;
    }
    
    header.naturalvoiceaziende,
    header.naturalvoiceparlata{
      background-color: rgb(62, 66, 47);
    }
    
    header.contacts{
      background-color: rgb(165, 183, 239);
    }
    
    .header{
        width: 100vw;
        height: 10vh;
        position: fixed;
        -webkit-backface-visibility: hidden;
        top: 0;
        left: 0;
    }
    
    .navMenu{
       height: calc(100% - 10vh); 
       margin-top: 10vh;
       padding: 0;
       padding-right: 2vh;
    }
    
    header .logo{
      width: 20vw;
      float: left;
      Height: auto;
    }
    
    header .bars{
      float: right;
      font-size: 2.5rem;
    }
    
    video{
        margin-top: 10vh
    }
    
    .slide.padded{
        padding: 5vh 5vw;
        font-size: 2rem;
    }
    
    .slide2 .columnsContainer{
        flex-direction: column;
    }
    
    .slide2 .columnsContainer .column{
        width: 100%;
        height: auto;
    }
    
    .slide2 .columnsContainer .column .subtitle{
        margin-bottom: 0;
    }
    
    .slide2 .columnsContainer .column .content{
        margin-bottom: 3em;
        font-size: 1.1rem;
    }
    
    .slide2 .columnsContainer .column.c2 .content{
        margin-bottom: 0;
    }
    
    .slide3{
        flex-direction: column;
        padding: 2vh 2vh !important;
        position: unset;
    }
    
    .slide3 .column.c0{
        width: 100%;
        position: unset;
        z-index: inherit;
    }
    
    .slide3 .column.c0 .mv{
        display: none;
    }
    
    .slide3 .column.c1{
        margin-top: 1rem;
        text-align: left;
    }
    
    .slide3 .column.c1{
        font-size: 5rem;
    }
    
    .slide3 .mv.mobile{
        position: unset;
        display: block;
        width: auto;
        height: auto;
        left: auto;
        bottom: auto;
        line-height: 1.2em;
        margin: 1.3em 0 1.7em 0;
        z-index: inherit;
    }
    
    footer .social{
      margin-left: 20vw;
      width: 60vw;
    }
    
    .slide5{
        height: 45vh;
    }
    
    .slide5, .slide5 .c0{
        font-size: 5.5rem;
        line-height: 5rem;
    }
    
    footer{
        font-size: 1rem;
    }
    
    footer p{
        padding: 0 0rem;
        line-height: 1rem;
    }
    
    .header,
    .header .white, 
    .header .black,
    .header .green,
    .header .blue,
    .header .orange{
      color: white;
    }
    
    .header .logo,
    .header .white .logo,
    .header .black .logo,
    .header .green .logo,
    .header .blue .logo,
    .header .orange .logo{
      filter: invert(100%) sepia(0%) saturate(7474%) hue-rotate(188deg) brightness(103%) contrast(105%);
    }
    
    .slide3 .column.c1 .small{
        font-size: 0.3em;
    }
    
    #naturalvoice .slide1{
      padding-top: 10vh;
      color: white;
      font-size: 3.5em;
      height: 30vh;
      box-sizing: border-box;
    }
    
    #naturalvoice .slide2{
      font-size: 1.5rem;
      height: 25vh;
      font-weight: 700;
    }
    
    #naturalvoice .slide3,
    #naturalvoice .slide7{
      font-size: 1.2rem;
      color: white;
      min-height: auto;
    }
    
    #naturalvoice .slide3 .content{
      font-size: 1.2rem;
     }
     
   #naturalvoice .slide3 .logos-column{
    display: none;
  }
    
    #naturalvoice .slide4{
      height: 75vh;
    }
    
    #naturalvoice .slide4 .logo{
      height: 100%;
      width: 100%;
      box-sizing: border-box;
      margin-left: auto;
      text-align: center;
      line-height: normal;
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      z-index: -1;
      background-image: url('./natural_voice_nv_01_op.png');
      background-size: auto 100%;
    }
    
    #naturalvoice .slide4 .logo img{
      display: none;
    }
    
    #naturalvoice .slide4 .label{
      position: unset !important;
      left: auto !important;
      top: auto !important;
      width: auto !important;
      z-index: 1;
    }
    
    #naturalvoice .slide8{
      font-size: 2em;
    }

    /* Cantata */
    #voceaziende .slide1,
    #voceparlata .slide1,
    #vocecantata .slide1{
      height: auto;
      height: 30vh;
    }
    
    #voceparlata .slide1{
      font-size: 2.3rem;
      height: 32vh;
    }
    
    #voceaziende .slide2,
    #voceparlata .slide2,
    #vocecantata .slide2{
      font-size: 1.2rem;
      height: auto;
      min-height: auto;
    }
    
    #voceaziende .slide3,
    #voceparlata .slide3,
    #vocecantata .slide3{
      font-size: 1.1rem;
    }
    
    #vocecantata .slide3,
    #voceaziende .slide3{
      flex-direction: column;
    }
    
    
    #voceaziende .slide3 table,
    #voceparlata .slide3 table,
    #vocecantata .slide3 table{
      width: 100%;
      text-align: left;
      margin-left: 0;
    }
    
    #voceaziende .slide3 table td,
    #voceparlata .slide3 table td,
    #vocecantata .slide3 table td{
      padding-bottom: 7vh;
    }
    
    #voceaziende .slide3 table td.type,
    #voceparlata .slide3 table td.type,
    #vocecantata .slide3 table td.type{
      text-align: left;
    }
    
    #vocecantata .slide3 table.inverted td{
      text-align: left;
    }
    
    #vocecantata .slide3 table.inverted td.type{
      text-align: right;
      padding-left: 5vw;
    }
    
    /* CONTACTS */
    #contacts .slide1{
      padding-top: 16vh;
      background-color: rgb(165 183 239);
      color: white;
      font-size: 18vw;
      height: 105vh;
      box-sizing: border-box;
      text-align: left;
    }
    
    #voceaziende .slide1.mobnopad{
      padding: 0 1rem;
      font-size: 1.8rem;
    }
    
    #voceaziende .slide3{
      background-repeat: repeat;
      background-position: center center;
      background-size: contain;
    }
    
    /* ABOUT US */
    #aboutus .abusheader{
      display: none;
    }
    
    #aboutus .slide1{
      height: auto;
      padding-top: 10vh;
      min-height: auto;
    }
    
    #aboutus .slide1 .marco{
      margin-top: 0;
      flex-direction: column;
      font-size: 1.2rem;
    }
    
    #aboutus .slide1 .marco .marcomarco{
      font-size: 3rem;
    }
    
    #aboutus .slide1 .marco img{
      height: auto;
      width: 100%;
      margin-right: 0;
      margin-bottom: 0;
    }
    
    #aboutus .slide2,
    #aboutus .slide3{
      /*font-size: 4vw;
      font-weight: 800;
      background-color: white;
      color: rgb(228, 74, 52);
      height: 90vh;
      text-align: left;
      display: block;
      background-image: url("natural_voice_nv_02.png");
      background-size: cover;
      background-position: left calc(61vw) top 0px;
      background-repeat: no-repeat;*/
      height: 60%;
    }
    
    #aboutus .slide2 .claim,
    #aboutus .slide3 .claim{
      width: 100%;
      float: inherit;
      font-size: 2rem;
      text-align: center;
      opacity: 2;
    }
    
    #aboutus .slide3{
      text-align: right;
      background-position: left calc(-60vw) top calc(-7vw);
    }
    
    #aboutus .slide4{
      font-size: 1.8rem;
    }
    
    #voceparlata .slide3 h1,
    #vocecantata .slide3 h1{
      padding: 0 0;
      margin-top: 0;
      font-size: 1.8rem;
      text-align: left;
      font-weight: 700;
      margin-block-start: 0;
    }
}